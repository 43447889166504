import {
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { C, H2, MarbleButton } from 'src/base';
import { P } from 'src/base/texts/P';
import { UserType } from 'server-sdk/types';
import { useRouter } from 'next/router';
import { Check } from '@mui/icons-material';
import {
  MARBLE_SUBSCRIPTION_PRICE,
  MARBLE_SUBSCRIPTION_PRICE_ANNUAL,
  MarbleBlue,
} from 'src/Constants';
import { track } from 'ui-sdk/src/util/track';
import { useUser } from 'ui-sdk/src/hooks';
import { theme } from 'ui-sdk/src/theme';
import { MarbleText } from 'src/base/texts/Text';
import { SETUP_FEE, SETUP_FEE_ANNUAL } from 'server-sdk/src/constants';

export const controlPerks = [
  'Property Listings',
  'Tenant Screening Reports',
  'Virtual Leasing Team',
  'Self-guided Tours',
  'Lease Generation & E-Signatures',
  '24/7 Maintenance Dispatch',
  'Utility Invoicing',
  'Rent Payments + Late Fee Management',
  'Financial Reports',
];

export const proTierPerks = [
  'Self-guided Tours',
  'Concierge Leasing Team',
  'Lease Generation & E-Signatures',
  '24/7 Maintenance Dispatch',
  'Utility Invoicing',
  'Rent Payments + Late Fee Management',
  'Financial Reports',
];

export default function PricingDisplay({
  cardless = false,
  setup = SETUP_FEE,
  cycle = 'monthly',
  pricing = MARBLE_SUBSCRIPTION_PRICE / 100,
  emailTest = false,
}): JSX.Element {
  const router = useRouter();
  const { me } = useUser();

  const tiers = [
    {
      isPopular: false,
      title: emailTest ? 'Management' : 'Premium Management',
      subheader: 'A fully managed service that takes care of all rental needs.',
      price: `${pricing}`,
      description: proTierPerks,
      buttonText: emailTest ? 'Email Nancy' : 'Start 14-day Trial',
      buttonSubText: cardless && 'No Credit Card Required',
      buttonVariant: 'primary',
    },
  ];

  // @ts-ignore
  const isMobile = useMediaQuery((th) => th.breakpoints.down('sm'));

  if (setup) {
    return (
      <Box>
        <Card
          // elevation={isMobile ? 0 : 1}
          sx={(theme) => ({
            boxShadow: '-1px 1px 10px 0 rgba(0,0,0,.12)',
            paddingInline: 10,
            paddingTop: 9,
            [theme.breakpoints.down('sm')]: {
              paddingInline: 2,
              paddingBlock: 5,
            },
          })}
        >
          <Grid
            container
            sx={(theme) => ({
              flexDirection: 'row',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            })}
            p={0}
          >
            <Grid item xs={12} sm={6} p={0}>
              <Grid
                container
                sx={(theme) => ({
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '85%',
                  [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    pb: 6,
                  },
                })}
              >
                <Grid
                  item
                  sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      pb: 6,
                    },
                  })}
                >
                  <MarbleText size="xl" weight="bold" pb={1}>
                    Premium Management
                  </MarbleText>
                  <MarbleText weight="med">
                    A fully managed service that takes care of all rental needs.
                  </MarbleText>
                </Grid>
                <Grid item>
                  <MarbleText
                    size={isMobile ? 'xl' : 'h2'}
                    weight="bold"
                    color="blue.main"
                  >
                    $
                    {cycle === 'annual' ?
                      MARBLE_SUBSCRIPTION_PRICE_ANNUAL / 100 :
                      pricing}{' '}
                    / month per unit
                  </MarbleText>
                  <MarbleText
                    size="l"
                    weight="med"
                    color="gray.2"
                    sx={{
                      pb: 4,
                    }}
                  >
                    + $
                    {cycle === 'annual' ? SETUP_FEE_ANNUAL / 100 : setup / 100}{' '}
                    leasing fee
                  </MarbleText>
                  <MarbleButton
                    title="Get Started"
                    fullWidth
                    onClick={() => {
                      track('Click Tier', {
                        tier: 'Premium Management with Setup',
                      });
                      if (me && me?.type === UserType.Owner) {
                        router.push('/owner');
                      } else if (me && me?.type === UserType.Renter) {
                        router.push('/renter');
                      } else {
                        router.push('/start');
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={(theme) => ({
                pl: 10,
                [theme.breakpoints.down('sm')]: {
                  pl: 2,
                },
              })}
            >
              <List
                sx={{
                  minHeight: '350px',
                  marginTop: theme.spacing(1),
                }}
              >
                {[
                  'Self-guided Tours',
                  'Concierge Leasing Team',
                  'Lease Generation & E-Signatures',
                  '24/7 Maintenance Dispatch',
                  'Utility Invoicing',
                  'Rent Payments + Late Fee Management',
                  'Financial Reports',
                ].map((line) => (
                  <ListItem sx={{ marginBottom: -2, marginTop: -2 }}>
                    <ListItemIcon sx={{ marginRight: -3, ml: -2 }}>
                      <Check color="success" />
                    </ListItemIcon>
                    <ListItemText key={line}>
                      <Typography variant="subtitle1">{line}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" component="main" style={{ padding: 0 }}>
      <Grid
        container
        spacing={5}
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
          },
          paddingBottom: '50px',
        })}
      >
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            xs="auto"
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md="auto"
            height="100%"
          >
            <Card
              style={{
                boxShadow: '-1px 1px 10px 0 rgba(0,0,0,.12)',
                maxWidth: 400,
              }}
            >
              <Box p={1} flexDirection="column" minHeight="35px">
                <Typography variant="subtitle2" align="right" color="secondary">
                  {tier?.isPopular ? 'Most Popular' : ' '}
                </Typography>
              </Box>
              <CardContent
                style={{
                  paddingLeft: theme.spacing(6),
                  paddingRight: theme.spacing(6),
                }}
              >
                <Box pb={2}>
                  <Typography
                    align="center"
                    variant="body1"
                    fontWeight={600}
                    fontSize={23}
                  >
                    {tier.title}
                  </Typography>
                  <Typography align="center" variant="subtitle1">
                    {tier.subheader}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 1,
                  }}
                >
                  <H2 style={{ color: MarbleBlue }}>${tier.price}</H2>
                  <P bold style={{ color: MarbleBlue }}>
                    /month/unit
                  </P>
                </Box>
                <CardActions
                  sx={{
                    pb: 1,
                  }}
                  style={{ flexDirection: 'column' }}
                >
                  <MarbleButton
                    fullWidth
                    title={tier.buttonText}
                    onClick={() => {
                      track('Click Tier', {
                        tier: tier.title,
                      });
                      if (emailTest) {
                        window.open(
                          "mailto:nancy@rentmarble.com?subject=Looking for more info about your services&body=Hi Nancy, I'm looking for more info about your services. Can you give me an overview?",
                        );
                      } else if (me && me?.type === UserType.Owner) {
                        router.push('/owner');
                      } else if (me && me?.type === UserType.Renter) {
                        router.push('/renter');
                      } else {
                        router.push('/start');
                      }
                    }}
                    style={{
                      display: 'block',
                      fontWeight: 600,
                    }}
                  />
                  <C
                    style={{
                      fontWeight: '500',
                      fontSize: 15,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    {tier.buttonSubText ?? ''}
                  </C>
                </CardActions>
                <List
                  sx={{
                    minHeight: '350px',
                    marginTop: theme.spacing(1),
                  }}
                >
                  {tier.description.map((line) => (
                    <ListItem sx={{ marginBottom: -2, marginTop: -2 }}>
                      <ListItemIcon sx={{ marginRight: -3, ml: -2 }}>
                        <Check color="success" />
                      </ListItemIcon>
                      <ListItemText key={line}>
                        <Typography variant="subtitle1">{line}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
